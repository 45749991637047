<template>
    <v-container>
        <v-card>
            <v-card-text>
                <v-textarea label="Token" v-model="token" disabled></v-textarea>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
const axios = require("axios");

export default {
    data() {
        return {
            token: ''
        }
    },
    mounted() {
        axios.get('/settings').then((resp) => {
            this.token = resp.data.KUBE_TOKEN;
        })
    }
}
</script>