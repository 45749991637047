<template>
    <v-container>
        <v-alert v-if="message !== ''" type="error" dismissible>{{ message }}
        </v-alert>
        <v-row no-gutters>
            <v-col cols="12" sm="6">
                <v-breadcrumbs :items="breadcrumbs" large></v-breadcrumbs>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex justify-end align-center">
                <v-btn color="green" class="ma-2 white--text" fab small :loading="creating" @click="createApplication">
                    <v-icon>
                        mdi-check
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-subheader>Basic Configuration</v-subheader>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field label="Name" v-model="name" :disabled="creating || edit"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                        <v-subheader>Gorse application name.</v-subheader>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div v-for="config in configs" v-bind:key="config.title">
            <v-subheader>{{ config.title }} Configuration</v-subheader>
            <v-card>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Key
                            </th>
                            <th class="text-left">
                                Value
                            </th>
                            <th class="text-left">
                                Description
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in config.items" v-bind:key="item.key">
                            <td>{{ item.key }}</td>
                            <td v-if="item.type === 'chips'" class="d-flex align-center">
                                <v-chip-group>
                                    <v-chip v-for="chip in values[item.ref]" v-bind:key="chip" small close
                                        @click:close="deleteChip(item.ref, chip)">{{ chip }}</v-chip>
                                </v-chip-group>
                                <v-text-field small :disabled="creating" v-model="fields[item.ref]"></v-text-field>
                                <v-btn icon :disabled="creating">
                                    <v-icon color="primary lighten-1" @click="addChip(item.ref)">
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </td>
                            <td v-else-if="item.type === 'switch'">
                                <v-switch :disabled="creating" v-model="values[item.ref]"></v-switch>
                            </td>
                            <td v-else-if="item.type === 'select'">
                                <v-select :items="item.options" :disabled="creating" v-model="values[item.ref]"
                                    persistent-hint return-object single-line></v-select>
                            </td>
                            <td v-else><v-text-field small :disabled="creating"
                                    v-model="values[item.ref]"></v-text-field></td>
                            <td>{{ item.description }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
        </div>
    </v-container>
</template>

<script>
const { faker } = require('@faker-js/faker');
const axios = require("axios");
import shared from '@/shared';

export default {
    data() {
        return {
            edit: false,
            name: faker.name.firstName().toLowerCase() + '-' + faker.name.lastName().toLowerCase(),
            creating: false,
            breadcrumbs: [
                {
                    text: 'Application',
                    disabled: false,
                    href: '/',
                },
                {
                    text: 'Create',
                    disabled: true
                }
            ],
            message: '',
            configs: shared.configs,
            values: shared.values,
            fields: {}
        }
    },
    mounted() {
        if (this.$route.params.name) {
            this.name = this.$route.params.name;
            axios.get(`/application/${this.$route.params.name}`).then((resp) => {
                this.breadcrumbs[1].text = 'Edit';
                this.edit = true;
                this.info = resp.data;
                this.values = shared.flattenJSON(resp.data.values);
            })
        }
    },
    methods: {
        createApplication: function () {
            this.creating = true;
            axios.post('/application', {
                name: this.name,
                values: shared.constructJSON(this.values, Object.keys(shared.values))
            }).then(() => {
                this.$router.push('/')
            }).catch((e) => {
                if (e.response) {
                    this.message = e.response.data;
                    this.creating = false;
                } else {
                    this.message = e;
                    this.creating = false;
                }
            })
        },
        addChip(ref) {
            if (!this.values[ref]) {
                this.values[ref] = [];
            }
            if (!this.values[ref].includes(this.fields[ref])) {
                this.values[ref].push(this.fields[ref]);
            }
            this.fields[ref] = '';
        },
        deleteChip(ref, chip) {
            if (this.values[ref]) {
                this.values[ref] = this.values[ref].filter(item => item !== chip)
            }
        }
    }
}
</script>
