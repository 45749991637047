<template>
  <div class="container">
    <table>
      <thead>
        <tr>
          <th>Title</th>
          <th>Controller</th>
          <th>Update</th>
          <th>Ops</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in releases" :key="item.id">
          <td><router-link :to="`/metadata/${item.id}`">{{ item.title }}</router-link></td>
          <td>{{ item.extra.controllerType }}</td>
          <td>{{ formatDateTime(item.extra.dateAdded) }}</td>
          <td>
            <a class="modal-trigger" href="#modal-delete" @click="openDeleteDialog(item)">
              <i class="xsmall material-icons red-text">delete</i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <div id="modal-delete" class="modal">
      <div class="modal-content">
        <h4>Delete {{ deleteItem.title }}</h4>
        <p>This deletes its metadata and cannot be undone.</p>
      </div>
      <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect btn-flat">Cancel</a>
        <a href="#!" class="modal-close waves-effect btn-flat red-text" @click="deleteMetadata()">Delete</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import M from "@materializecss/materialize";

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Metadata',
          disabled: false,
          href: '/',
        },
        {
          text: 'List',
          disabled: true
        }
      ],
      createDialog: false,
      createName: '',
      deleteDialog: false,
      deleteItem: '',
      releases: [],
      alertDisplay: false,
      alertType: 'success',
      alertMessage: '',
    }
  },
  mounted() {
    M.Modal.init(document.querySelectorAll('.modal'), {});
    M.FormSelect.init(document.querySelectorAll('select'), {});
    this.listMetadatas();
  },
  methods: {
    openDeleteDialog: function (item) {
      this.deleteDialog = true;
      this.deleteItem = item;
      this.confirm = '';
    },
    listMetadatas: function () {
      axios.get('/api/metadatas').then((response) => {
        this.releases = response.data;
      }).catch((e) => {
        if (e.response) {
          M.toast({ html: e.response.data })
        } else {
          M.toast({ html: e })
        }
      })
    },
    deleteMetadata: function () {
      this.deleteDialog = false;
      axios.delete(`/api/metadata/${this.deleteItem.id}`).then(() => {
        M.toast({ html: 'Metadata deleted' })
        this.listMetadatas();
      }).catch((e) => {
        if (e.response) {
          M.toast({ html: e.response.data })
        } else {
          M.toast({ html: e })
        }
      })
    },
    formatDateTime: function (string) {
      return moment(string).format('YYYY/MM/DD');
    }
  }
}
</script>
