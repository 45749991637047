import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import AppPage from "./components/AppPage.vue";
import HomePage from "./components/HomePage.vue";
import PrivacyPolicy from "./components/PrivacyPolicy.vue";
import Metadata from "./components/Metadata.vue";
import CreateApp from "./components/CreateApp.vue";
import ListMetadatas from "./components/ListMetadatas";
import ListShares from "./components/ListShares";
import ListFlags from "./components/ListFlags";
import Settings from "./components/Settings";
import Overview from "./components/Overview";
import Share from "./components/Share";

import Default from "@/layouts/Default.vue";
import Dashboard from "@/layouts/Dashboard.vue";
import Login from "@/layouts/Login.vue";

import "@materializecss/materialize/dist/css/materialize.min.css";
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(VueRouter);
Vue.component("default-layout", Default);
Vue.component("dashboard-layout", Dashboard);
Vue.component("login-layout", Login);

const routes = [
  { path: "/", component: HomePage },
  { path: "/app/:id", component: AppPage },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/metadata/:id", component: Metadata, meta: { layout: "dashboard" } },
  { path: "/share/:id", component: Share, meta: { layout: "dashboard" } },
  { path: "/create", component: CreateApp },
  { path: "/edit/:name", component: CreateApp },
  { path: "/settings", component: Settings },
  { path: "/login", name: "login", meta: { layout: "login" } },
  { path: "/dashboard", component: Overview, meta: { layout: "dashboard" } },
  { path: "/dashboard/metadata", component: ListMetadatas, meta: { layout: "dashboard" } },
  { path: "/dashboard/share", component: ListShares, meta: { layout: "dashboard" } },
  { path: "/dashboard/flag", component: ListFlags, meta: { layout: "dashboard" } },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
