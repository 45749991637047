<template>
    <div>
        <nav class="deep-purple">
            <div class="nav-wrapper">
                <a href="#" class="brand-logo">Dashboard</a>
            </div>
        </nav>
        <br><br><br><br><br>
        <div class="row container">
            <div class="col s12 l6 offset-l3">
                <div class="card">
                    <div class="card-content">
                        <span class="card-title">Administrator Login</span>
                        <div class="row">
                            <div class="input-field col s12">
                                <input id="username" v-model="username">
                                <label for="username">Username</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="input-field col s12">
                                <input id="password" v-model="password" type="password">
                                <label for="password">Password</label>
                            </div>
                        </div>
                    </div>
                    <div class="card-action">
                        <a href="#" @click="login">Login</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import M from "@materializecss/materialize";

export default {
    data() {
        return {
            username: '',
            password: '',
        }
    },
    methods: {
        login() {
            var form = new FormData();
            form.append('username', this.username);
            form.append('password', this.password);
            axios({
                method: "post",
                url: "/login",
                data: form,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(() => {
                this.$router.push('/dashboard');
            }).catch((e) => {
                if (e.response) {
                    M.toast({html: e.response.data})
                } else {
                    M.toast({html: e})
                }
            })
        }
    }
};
</script>
