<template>
    <div class="deep-purple lighten-2">
        <div class="container white-text">
            <div class="row">
                <div class="col l6 s12">
                    <div class="row">
                        <h3>Play {{ metadata.title }} on Android</h3>
                    </div>
                    <div class="row">
                        <p>{{ metadata.originalDescription }}</p>
                    </div>
                    <div class="row">
                        <a target="_blank"
                            href='https://play.google.com/store/apps/details?id=games.flashlite&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                                alt='Get it on Google Play' width="200"
                                src='https://cdn.flashlite.games/img/google-play-badge.png' /></a>
                        <a target="_blank"
                            href='https://apkcombo.com/flashlittle-flash-game-player/games.flashlite/'><img width="200"
                                src='https://cdn.flashlite.games/img/download-apk-badge.png' /></a>
                    </div>
                </div>
                <div class="col l6 s12">
                    <br><br>
                    <a :href="'flashlite://app/' + metadata.id">
                        <img style="max-width: 500px; width: 100%;" :src="screenshot()" />
                    </a>
                    <br><br>
                </div>
            </div>
            <br>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            metadata: null
        }
    },
    mounted() {
        axios.get(`/api/metadata/${this.$route.params.id}`).then((resp) => {
            this.metadata = resp.data;
            document.title = "FlashLittle | Play " + this.metadata.title + "on Android"
        })
    },
    methods: {
        screenshot: function () {
            return "https://infinity.unstable.life/images/Screenshots/" +
                this.metadata.id.substring(0, 2) + "/" +
                this.metadata.id.substring(2, 4) + "/" +
                this.metadata.id + ".png?type=jpg"
        },
    }
}
</script>