const flattenJSON = (obj = {}, res = {}, extraKey = "") => {
  for (const key in obj) {
    if (typeof obj[key] !== "object" || Array.isArray(obj[key])) {
      res[extraKey + key] = obj[key];
    } else {
      flattenJSON(obj[key], res, `${extraKey}${key}.`);
    }
  }
  return res;
};

const constructJSON = (obj, fields) => {
  let json = {};
  for (const key in obj) {
    if (fields.includes(key)) {
      const names = key.split(".");
      let o = json;
      for (const i in names) {
        const name = names[i];
        if (Number(i) + 1 < names.length) {
          if (!o[name]) {
            o[name] = {};
          }
          o = o[name];
        } else {
          o[name] = obj[key];
        }
      }
    }
  }
  return json;
};

export default {
  configs: [
    {
      title: "API",
      items: [
        {
          key: "auto_insert_user",
          value: true,
          type: "switch",
          ref: "gorse.server.insert.users",
          description: "Insert new users while inserting feedback.",
        },
        {
          key: "auto_insert_item",
          value: true,
          type: "switch",
          ref: "gorse.server.insert.items",
          description: "Insert new items while inserting feedback.",
        },
      ],
    },
    {
      title: "Data Source",
      items: [
        {
          key: "positive_feedback_types",
          type: "chips",
          ref: "gorse.recommend.feedback.positive",
          description: "The feedback types for positive events.",
        },
        {
          key: "read_feedback_types",
          type: "chips",
          ref: "gorse.recommend.feedback.read",
          description: "The feedback types for read events.",
        },
        {
          key: "positive_feedback_ttl",
          ref: "gorse.recommend.ttl.feedback",
          description:
            "The time-to-live (days) of positive feedback, 0 means disabled.",
        },
        {
          key: "item_ttl",
          ref: "gorse.recommend.ttl.item",
          description: "The time-to-live (days) of items, 0 means disabled.",
        },
      ],
    },
    {
      title: "Popular Recommendation",
      items: [
        {
          key: "popular_window",
          ref: "gorse.recommend.ttl.popular",
          description: "The time window of popular items.",
        },
      ],
    },
    {
      title: "User Neighbors",
      items: [
        {
          key: "neighbor_type",
          type: "select",
          ref: "gorse.recommend.neighbors.users.type",
          description: "The type of neighbors for users.",
          options: ["similar", "related", "auto"],
        },
        // {
        //     key: 'enable_index',
        //     description: 'Enable approximate user neighbor searching using vector index.'
        // },
        // {
        //     key: 'index_recall',
        //     description: 'Minimal recall for approximate user neighbor searching.'
        // },
        // {
        //     key: 'index_fit_epoch',
        //     description: 'Maximal number of fit epochs for approximate user neighbor searching vector index.'
        // }
      ],
    },
    {
      title: "Item Neighbors",
      items: [
        {
          key: "neighbor_type",
          type: "select",
          ref: "gorse.recommend.neighbors.items.type",
          description: "The type of neighbors for items.",
          options: ["similar", "related", "auto"],
        },
        // {
        //     key: 'enable_index',
        //     description: 'Enable approximate item neighbor searching using vector index.'
        // },
        // {
        //     key: 'index_recall',
        //     description: 'Minimal recall for approximate item neighbor searching.'
        // },
        // {
        //     key: 'index_fit_epoch',
        //     description: 'Maximal number of fit epochs for approximate item neighbor searching vector index.'
        // }
      ],
    },
  ],
  values: {
    "gorse.server.insert.users": true,
    "gorse.server.insert.items": true,
    "gorse.recommend.feedback.positive": [],
    "gorse.recommend.feedback.read": [],
    "gorse.recommend.ttl.feedback": 0,
    "gorse.recommend.ttl.item": 0,
    "gorse.recommend.ttl.popular": "4320h",
    "gorse.recommend.neighbors.users.type": "auto",
    "gorse.recommend.neighbors.items.type": "auto",
  },
  flattenJSON: flattenJSON,
  constructJSON: constructJSON,
  keyCodeToName: function (code) {
    switch (code) {
      case 0:
        return "🚫";
      case 13:
        return "ENTER";
      case 27:
        return "ESC";
      case 32:
        return "SPACE";
      case 37:
        return "⬅️";
      case 38:
        return "⬆️";
      case 39:
        return "➡️";
      case 40:
        return "⬇️";
      default:
        return String.fromCharCode(code);
    }
  }
};
