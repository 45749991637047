<template>
  <div class="container">
    <ul class="collapsible">
      <li v-for="flag in flags" v-bind:key="flag._id">
        <div class="collapsible-header"><i class="material-icons">message</i>{{ flag.title }}</div>
        <div class="collapsible-body"><span>{{ flag.text }}</span></div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import M from "@materializecss/materialize";

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Shares',
          disabled: false,
          href: '/',
        },
        {
          text: 'List',
          disabled: true
        }
      ],
      createDialog: false,
      approveItem: '',
      deleteDialog: false,
      closeItem: '',
      flags: [],
      alertDisplay: false,
      alertType: 'success',
      alertMessage: '',
    }
  },
  mounted() {
    var elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems, {});
    this.listFlags();
  },
  methods: {
    openApproveDialog: function (item) {
      this.approveItem = item;
    },
    openCloseDialog: function (item) {
      this.closeItem = item;
    },
    openAlert: function (type, message) {
      this.alertDisplay = true;
      this.alertType = type;
      this.alertMessage = message;
      setTimeout(() => {
        this.alertDisplay = false;
      }, 3000)
    },
    listFlags: function () {
      axios.get('/api/flags').then((response) => {
        this.flags = response.data;
      }).catch((e) => {
        if (e.response) {
          M.toast({ html: e.response.data })
        } else {
          M.toast({ html: e })
        }
      })
    },
    closeShare: function () {
      axios.delete(`/api/share/${this.closeItem._id}`).then(() => {
        M.toast({ html: 'Share closed' })
        this.listShares();
      }).catch((e) => {
        if (e.response) {
          M.toast({ html: e.response.data })
        } else {
          M.toast({ html: e })
        }
      })
    },
    approveShare: function () {
      axios.post('/api/metadata', this.approveItem).then(() => {
        axios.delete(`/api/share/${this.approveItem._id}`).then(() => {
          M.toast({ html: 'Metadata created' })
          this.listShares();
        }).catch((e) => {
          if (e.response) {
            M.toast({ html: e.response.data })
          } else {
            M.toast({ html: e })
          }
        })
      }).catch((e) => {
        if (e.response) {
          M.toast({ html: e.response.data })
        } else {
          M.toast({ html: e })
        }
      })
    },
    formatDateTime: function (string) {
      return moment(string).format('YYYY/MM/DD');
    }
  }
}
</script>
