import { render, staticRenderFns } from "./Metadata.vue?vue&type=template&id=806f74a4"
import script from "./Metadata.vue?vue&type=script&lang=js"
export * from "./Metadata.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.7.3_vue-template-compiler@2.7.14_webpack@5.75.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports