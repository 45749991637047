<template>
  <div class="container">
    <table>
      <thead>
        <tr>
          <th class="text-left">
            Title
          </th>
          <th class="text-left">
            Controller
          </th>
          <th class="text-left">
            Time
          </th>
          <th class="text-left">
            Ops
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in releases" :key="item.name">
          <td><router-link :to="`/share/${item._id}`">{{ item.title }}</router-link></td>
          <td>{{ item.extra.controllerType }}</td>
          <td>{{ formatDateTime(item.extra.dateAdded) }}</td>
          <td>
            <a target="_blank" :href="'https://ooooooooo.ooo/static/?' + item.id">
              <i class="xsmall material-icons green-text">play_arrow</i>
            </a>
            <a class="modal-trigger" href="#modal-check">
              <i class="xsmall material-icons green-text" @click="openApproveDialog(item)">check</i>
            </a>
            <a class="modal-trigger" href="#modal-close">
              <i class="xsmall material-icons red-text" @click="openCloseDialog(item)">close</i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <div id="modal-check" class="modal">
      <div class="modal-content">
        <h4>Approve {{ approveItem.title }}</h4>
        <p>
          <label>
            <input type="checkbox" v-model="dateModified" />
            <span>Use modifed date as added date</span>
          </label>
        </p>
        <p>
          <label>
            <input type="checkbox" v-model="featured" />
            <span>Enable compatibility check</span>
          </label>
        </p>
      </div>
      <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect btn-flat">Cancel</a>
        <a href="#!" class="modal-close waves-effect btn-flat red-text" @click="approveShare()">Approve</a>
      </div>
    </div>

    <div id="modal-close" class="modal">
      <div class="modal-content">
        <h4>Close {{ closeItem.title }}</h4>
        <p>This deletes the share and cannot be undone.</p>
      </div>
      <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect btn-flat">Cancel</a>
        <a href="#!" class="modal-close waves-effect btn-flat red-text" @click="closeShare()">Close</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import M from "@materializecss/materialize";

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Shares',
          disabled: false,
          href: '/',
        },
        {
          text: 'List',
          disabled: true
        }
      ],
      createDialog: false,
      approveItem: '',
      deleteDialog: false,
      closeItem: '',
      releases: [],
      alertDisplay: false,
      alertType: 'success',
      alertMessage: '',
      dateModified: false,
      featured: false,
    }
  },
  mounted() {
    var elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {});
    this.listShares();
  },
  methods: {
    openApproveDialog: function (item) {
      this.approveItem = item;
    },
    openCloseDialog: function (item) {
      this.closeItem = item;
    },
    openAlert: function (type, message) {
      this.alertDisplay = true;
      this.alertType = type;
      this.alertMessage = message;
      setTimeout(() => {
        this.alertDisplay = false;
      }, 3000)
    },
    listShares: function () {
      axios.get('/api/shares').then((response) => {
        this.releases = response.data;
      }).catch((e) => {
        if (e.response) {
          M.toast({ html: e.response.data })
        } else {
          M.toast({ html: e })
        }
      })
    },
    closeShare: function () {
      axios.delete(`/api/share/${this.closeItem._id}`).then(() => {
        M.toast({ html: 'Share closed' })
        this.listShares();
      }).catch((e) => {
        if (e.response) {
          M.toast({ html: e.response.data })
        } else {
          M.toast({ html: e })
        }
      })
    },
    approveShare: function () {
      axios.post('/api/metadata', this.approveItem, {
        params: {
          date: this.dateModified ? "modified" : "",
          featured: this.featured ? "true" : "",
        }
      }).then(() => {
        axios.delete(`/api/share/${this.approveItem._id}`).then(() => {
          M.toast({ html: 'Metadata created' })
          this.listShares();
        }).catch((e) => {
          if (e.response) {
            M.toast({ html: e.response.data })
          } else {
            M.toast({ html: e })
          }
        })
      }).catch((e) => {
        if (e.response) {
          M.toast({ html: e.response.data })
        } else {
          M.toast({ html: e })
        }
      })
    },
    formatDateTime: function (string) {
      return moment(string).format('YYYY/MM/DD');
    }
  }
}
</script>
