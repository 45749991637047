<template>
    <div class="has-fixed-sidenav">
        <header>
            <nav class="deep-purple">
                <a href="#" data-target="slide-out" class="sidenav-trigger"><i class="material-icons">menu</i></a>
                <div class="nav-wrapper">
                    <ul id="nav-mobile" class="right hide-on-med-and-down">
                        <li><a href="#"><i class="material-icons">exit_to_app</i></a></li>
                    </ul>
                </div>
            </nav>
            <ul id="slide-out" class="sidenav sidenav-fixed">
                <li>
                    <div class="user-view">
                        <a href="#"><img class="circle" src="https://cdn.flashlite.games/img/icon.png"></a>
                        <a href="#name"><span class="black-text name">FlashLittle Dashboard</span></a>
                    </div>
                </li>
                <li v-for="item in items" :key="item.title" :class="{ 'active': $route.path == item.link }"><router-link
                        :to="item.link"><i class="material-icons">{{ item.icon
                        }}</i>
                        {{ item.title }}</router-link></li>
            </ul>
        </header>
        <main>
            <router-view></router-view>
        </main>
    </div>
</template>

<script>
import M from "@materializecss/materialize";

export default {
    name: 'App',
    data() {
        return {
            version: '',
            items: [
                { title: 'Overview', icon: 'dashboard', link: '/dashboard' },
                { title: 'Metadata', icon: 'library_books', link: '/dashboard/metadata' },
                { title: 'Share', icon: 'folder_shared', link: '/dashboard/share' },
                { title: 'Flag', icon: 'flag', link: '/dashboard/flag' }
            ]
        }
    },
    mounted() {
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, {});
    }
}
</script>

<style>
@media all and (min-width:760px) and (max-width: 4096px) {
    div.has-fixed-sidenav {
        padding-left: 300px;
    }

    #slide-out {
        transform: translateX(0%);
    }
}
</style>