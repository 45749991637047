<template>
    <div class="container">
        <div class="row">
            <div class="col s12 l12">
                <h1>{{ info.title }}</h1>
                <div class="chip" v-for="tag in info.tags" v-bind:key="tag">
                    {{ tag }}
                </div>
                <p v-html="info.originalDescription.replace(/(?:\r\n|\r|\n)/g, '<br/>')"></p>
            </div>
        </div>
        <div class="row">
            <div class="col s12 l4">
                <div class="card">
                    <div class="card-image">
                        <img :src="screenshot()">
                    </div>
                    <div class="card-content">
                        <table class="centered">
                            <thead>
                                <tr>
                                    <th>Button</th>
                                    <th>Key</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><i class="material-icons">gamepad</i></td>
                                    <td v-if="info.extra.movementKeys == 'WASD'">WASD</td>
                                    <td v-else>⬆️⬅️⬇️➡️</td>
                                </tr>
                                <tr v-for="btn in buttons" v-bind:key="btn.button">
                                    <td>{{ btn.button }}</td>
                                    <td><a class="modal-trigger" href="#modal1">{{
                                        keyCodeToName(info.extra[btn.key]) }}</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col s12 l8">
                <div class="card">
                    <div class="card-content">
                        <table>
                            <tbody>
                                <!-- Orientation -->
                                <tr>
                                    <td><strong>Orientation</strong></td>
                                    <td>
                                        <i v-if="info.extra.portrait" class="material-icons">stay_current_portrait</i>
                                        <i v-else class="material-icons">stay_primary_landscape</i>
                                    </td>
                                </tr>
                                <!-- Controller Type -->
                                <tr>
                                    <td><strong>Controller Type</strong></td>
                                    <td>
                                        <i v-if="info.extra.controllerType === 'D-pad'" class="material-icons">gamepad</i>
                                        <i v-else-if="info.extra.controllerType === 'Touchpad'"
                                            class="material-icons">crop_din</i>
                                        <i v-else-if="info.extra.controllerType === 'Joystick'"
                                            class="material-icons">filter_tilt_shift</i>
                                        <i v-else class="material-icons">touch_app</i>
                                    </td>
                                </tr>
                                <!-- Language -->
                                <tr>
                                    <td><strong>Language</strong></td>
                                    <td>{{ info.language }}</td>
                                </tr>
                                <!-- Play Mode -->
                                <tr>
                                    <td><strong>Play Mode</strong></td>
                                    <td>{{ info.playMode }}</td>
                                </tr>
                                <!-- Series -->
                                <tr>
                                    <td><strong>Series</strong></td>
                                    <td>{{ info.series }}</td>
                                </tr>
                                <!-- Developer -->
                                <tr>
                                    <td><strong>Developer</strong></td>
                                    <td>{{ info.developer }}</td>
                                </tr>
                                <!-- Publisher -->
                                <tr>
                                    <td><strong>Publisher</strong></td>
                                    <td>{{ info.publisher }}</td>
                                </tr>
                                <!-- Release Date -->
                                <tr>
                                    <td><strong>Release Date</strong></td>
                                    <td>{{ info.releaseDate }}</td>
                                </tr>
                                <!-- Source -->
                                <tr>
                                    <td><strong>Source</strong></td>
                                    <td>{{ info.source }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-action">
                        <a target="_blank" :href="'https://ooooooooo.ooo/static/?' + info.id">Play Game</a>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal1" class="modal">
            <div class="modal-content">
                <h4>Modal Header</h4>
                <p>A bunch of text</p>
            </div>
            <div class="modal-footer">
                <a href="#!" class="modal-close waves-effect waves-green btn-flat">Agree</a>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require("axios");
import shared from '@/shared';
import M from "@materializecss/materialize";

export default {
    data() {
        return {
            breadcrumbs: [
                {
                    text: 'Metadata',
                    disabled: false,
                    href: '/',
                },
                {
                    text: this.$route.params.name,
                    disabled: true,
                },
            ],
            message: '',
            configs: shared.configs,
            values: {},
            info: {},
            deleteDialog: false,
            deletion: '',
            confirm: '',
            buttons: [
                {
                    button: 'A',
                    key: 'buttonA',
                },
                {
                    button: 'B',
                    key: 'buttonB',
                },
                {
                    button: 'X',
                    key: 'buttonX',
                },
                {
                    button: 'Y',
                    key: 'buttonY',
                },
                {
                    button: 'LB',
                    key: 'buttonLB',
                },
                {
                    button: 'LT',
                    key: 'buttonLT',
                },
                {
                    button: 'RB',
                    key: 'buttonRB',
                },
                {
                    button: 'RT',
                    key: 'buttonRT',
                },
                {
                    button: 'Start',
                    key: 'buttonStart',
                },
                {
                    button: 'Select',
                    key: 'buttonSelect',
                }
            ]
        }
    },
    mounted() {
        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {});
        axios.get(`/api/share/${this.$route.params.id}`).then((resp) => {
            this.info = resp.data;
            this.values = shared.flattenJSON(resp.data.values);
        })
    },
    methods: {
        screenshot: function () {
            return "https://infinity.unstable.life/images/Screenshots/" +
                this.info.id.substring(0, 2) + "/" +
                this.info.id.substring(2, 4) + "/" +
                this.info.id + ".png?type=jpg"
        },
        keyCodeToName: function (key) {
            return shared.keyCodeToName(key);
        },
        openDeleteDialog: function (name) {
            this.deleteDialog = true;
            this.deletion = name;
            this.confirm = '';
        },
        deleteApplication: function () {
            if (this.confirm === this.deletion) {
                axios.delete(`/application/${this.deletion}`).then(() => {
                    this.$router.push('/');
                }).catch((e) => {
                    if (e.response) {
                        this.message = e.response.data;
                    } else {
                        this.message = e;
                    }
                })
            } else {
                this.message = 'Confirm deletion failed';
            }
        },
    }
}
</script>
