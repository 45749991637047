<template>
    <div class="deep-purple lighten-2">
        <div class="container white-text">
            <div class="row">
                <div class="col l6 s12">
                    <div class="row">
                        <h1>FlashLittle</h1>
                    </div>
                    <div class="row">
                        <h5>Flash emulator for Android based on <a href="https://ruffle.rs/" target="_blank">Ruffle</a>.
                        </h5>
                    </div>
                    <div class="row">
                        <a target="_blank"
                            href='https://play.google.com/store/apps/details?id=games.flashlite&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                                alt='Get it on Google Play' width="200"
                                src='https://cdn.flashlite.games/img/google-play-badge.png' /></a>
                        <a target="_blank"
                            href='https://apkcombo.com/flashlittle-flash-game-player/games.flashlite/'><img width="200"
                                src='https://cdn.flashlite.games/img/download-apk-badge.png' /></a>
                    </div>
                </div>
                <div class="col l6 s12">
                    <br><br>
                    <img style="max-width: 500px; width: 100%;"
                        src="https://cdn.flashlite.games/img/2023-11-18-22-44-12-149.jpg" />
                    <br><br>
                </div>
            </div>
            <div class="row">
                <div class="col l4 s12">
                    <h5>Games Library</h5>
                    <p>FlashLittle maintains a library of compatible games. Games in the library have been tested mannualy
                        with virtual controllers added. More classical games will be added after testing.</p>
                </div>
                <div class="col l4 s12">
                    <h5>Virtual Controllers</h5>
                    <p>FlashLittle supports virtual controllers. The touch screen is sufficient for mouse-only games, but
                        you
                        can also play games using a virtual d-pad, joystick, or touchpad with custom key bindings.</p>
                </div>
                <div class="col l4 s12">
                    <h5>Flashpoint Archive</h5>
                    <p>FlashLittle support download Flash games from the <a href="https://flashpointarchive.org/"
                            target="_blank">Flashpoint Archive</a>. Flashpoint Archive is a community
                        effort to preserve games and animations from the web. Since December 2017, over 170,000 games
                        and
                        animations have been preserved.</p>
                </div>
            </div>
            <div class="row">
                <div class="col l12 s12">
                    <h5>Gameplay</h5>
                </div>
            </div>
            <div class="row">
                <div class="col l4 s12">
                    <div class="video-container">
                        <iframe width="700" height="315"
                            src="https://www.youtube.com/embed/dEnnpvhYQ-8?si=nWonVwdvJ7HYPuwn"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen></iframe>
                    </div>
                </div>
                <div class="col l4 s12">
                    <div class="video-container">
                        <iframe width="700" height="315"
                            src="https://www.youtube.com/embed/OeJAeWoL76Y?si=nWonVwdvJ7HYPuwn"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen></iframe>
                    </div>
                </div>
                <div class="col l4 s12">
                    <div class="video-container">
                        <iframe width="560" height="315"
                            src="https://www.youtube.com/embed/ffgRXCgbG20?si=nWonVwdvJ7HYPuwn"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <br>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        document.title = 'FlashLittle | Flash emulator for Android'
    }
}
</script>